import { createSlice } from '@reduxjs/toolkit'

export interface IValue {
  step1Text: string;
  step2Text: string;
  step3Text: string;
  step4Text: string;
}

const initialState: IValue = {
  step1Text: "",
  step2Text: "",
  step3Text: "",
  step4Text: ""
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    addMainText: (state, action) => {
      state.step1Text = action.payload
    },
    deleteMainText: (state) => {
      state.step1Text = ""
    },
    addStep2Text: (state, action) => {
      state.step2Text = action.payload
    },
    deleteStep2Text: (state) => {
      state.step2Text = ""
    },
    addStep3Text: (state, action) => {
      state.step3Text = action.payload
    },
    deleteStep3Text: (state) => {
      state.step2Text = ""
    },
    addStep4Text: (state, action) => {
      state.step4Text = action.payload
    },
    deleteStep4Text: (state) => {
      state.step2Text = ""
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addMainText,
  deleteMainText,
  addStep2Text,
  deleteStep2Text,
  addStep3Text,
  deleteStep3Text,
  addStep4Text,
  deleteStep4Text,
} = counterSlice.actions

export default counterSlice.reducer