import React from "react";
import "./step3.scss";
import {ActItem} from "../act-item/ActItem";
import {TextField} from "@mui/material";
import {ButtonBlock} from "../button-block/ButtonBlock";
import {useDispatch, useSelector} from "react-redux";
import {addStep3Text, deleteStep3Text} from "../../redux/TextSlice";
import {FooterButtonBlock} from "../footer-button-block/FooterButtonBlock";
import {Spinner} from "../spinner/Spinner";

interface Step3Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const Step3: React.FC<Step3Props> = ({step, setStep}) => {
  // @ts-ignore
  const text = useSelector(state => state.text.step3Text);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(text);
  const [isLoading, setLoading] = React.useState(false);

  const deleteValue = () => {
    dispatch(deleteStep3Text());
    setValue("");
  };

  React.useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1500)
  }, []);

  const addValue = (value: string) => {
    setLoading(true);
    dispatch(addStep3Text(value));
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <div className="step3">
      {isLoading ? <Spinner/> :
        <div className="act-body">
          <ActItem
            title="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus dolore dolorem dolores iusto officia tenetur totam veritatis? A animi corporis dolor eveniet
        obcaecati odit repellat repellendus tempora? Eveniet, in.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium asperiores at delectus deleniti dolores ducimus facilis fugit illum, impedit libero nemo
        neque nisi obcaecati, quod repudiandae rerum sint veritatis!"
            link="#"/>
          <ActItem
            title="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus dolore dolorem dolores iusto officia tenetur totam veritatis? A animi corporis dolor eveniet
        obcaecati odit repellat repellendus tempora? Eveniet, in.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium asperiores at delectus deleniti dolores ducimus facilis fugit illum, impedit libero nemo
        neque nisi obcaecati, quod repudiandae rerum sint veritatis!"
            link="#"/>
          <ActItem
            title="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus dolore dolorem dolores iusto officia tenetur totam veritatis? A animi corporis dolor eveniet
        obcaecati odit repellat repellendus tempora? Eveniet, in.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium asperiores at delectus deleniti dolores ducimus facilis fugit illum, impedit libero nemo
        neque nisi obcaecati, quod repudiandae rerum sint veritatis!"
            link="#"/>
        </div>}
      <div className="your-wishes">
        Ваші побажання та зауваження:
        <TextField
          id="outlined-multiline-static"
          label=""
          multiline
          rows={3}
          fullWidth
          value={value}
          onChange={event => setValue(event.target.value)}
        />
        <ButtonBlock
          value={value}
          deleteValue={deleteValue}
          addValue={addValue}
        />
      </div>
      <FooterButtonBlock
        step={step}
        setStep={setStep}
        text={!isLoading ? " " : ""}
        isLoading={isLoading}
      />
    </div>
  )
};