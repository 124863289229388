import React from "react";
import {ButtonComponent} from "../../UI/button/ButtonComponent";

interface ButtonBlockProps {
  value?: string | undefined;
  deleteValue?: (() => void) | undefined
  addValue?: ((value: string) => void) | undefined;
}

export const ButtonBlock: React.FC<ButtonBlockProps> = ({
                                                          value,
                                                          deleteValue,
                                                          addValue
}) => {

  return (
    <>
      <div className="button-block">
        <ButtonComponent
          title="Очистити"
          color="#808080"
          borderColor="#BFBFBF"
          onClick={() => {
            if (deleteValue) {
              deleteValue();
            }
          }}
        />
        <ButtonComponent
          title="Зберегти"
          color="#0CA789"
          borderColor="#85D3C4"

          onClick={() => {
            if (addValue) {
              addValue(value ? value : "");
            }
          }}
        />
      </div>
    </>
  )
};