import React from "react";
import "./header.scss";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header:React.FC<HeaderProps> = ({open, setOpen}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDrawer = () => {
    setOpen(true);
    handleClose();
  };

  return (
    <header>
      <div className="wrapper">
        <MenuIcon onClick={openDrawer}/>
        <Typography component="p" variant="body1">Pretor.ai</Typography>
      </div>
      <Typography component="h1" variant="h5">Конструктор позовних заяв</Typography>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle sx={{fontSize: "2.5rem"}}/>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Профіль</MenuItem>
        <MenuItem onClick={handleClose}>Аккаунт</MenuItem>
      </Menu>
    </header>
  )
};