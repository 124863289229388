import React from "react";
import "./step4.scss";
import {TextField} from "@mui/material";
import {ButtonBlock} from "../button-block/ButtonBlock";
import {FooterButtonBlock} from "../footer-button-block/FooterButtonBlock";
import {addStep4Text, deleteStep4Text} from "../../redux/TextSlice";
import {useDispatch, useSelector} from "react-redux";

interface Step4Props {
  step: number;
  setStep:  React.Dispatch<React.SetStateAction<number>>;
}

export const Step4: React.FC<Step4Props> = ({step, setStep}) => {
  // @ts-ignore
  const text = useSelector(state => state.text.step3Text);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(text);

  const [isLoading] = React.useState(false);

  const deleteValue = () => {
    dispatch(deleteStep4Text());
    setValue("");
  };

  const addValue = (value: string) => {
    dispatch(addStep4Text(value));
  };

  return (
    <div className="step4">
        <TextField
          label="Вступна частина"
          fullWidth
          multiline
          variant="outlined"
          sx={{marginBottom: "20px"}}
          value="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam deserunt dolor doloribus ducimus error hic, illo libero necessitatibus nostrum nulla
          numquam quidem recusandae repellendus similique sit vel vero voluptatibus.Assumenda dignissimos dolore ipsa maiores natus. Aperiam aspernatur, assumenda at
          blanditiis cumque distinctio ea eaque eligendi enim explicabo fuga hic ipsa nihil perferendis quae quam quibusdam recusandae sit ullam velit."/>

        <TextField
          label="Описова частина"
          fullWidth
          multiline
          variant="outlined"
          sx={{marginBottom: "20px"}}
          value="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam deserunt dolor doloribus ducimus error hic, illo libero necessitatibus nostrum nulla
          numquam quidem recusandae repellendus similique sit vel vero voluptatibus.Assumenda dignissimos dolore ipsa maiores natus. Aperiam aspernatur, assumenda at
          blanditiis cumque distinctio ea eaque eligendi enim explicabo fuga hic ipsa nihil perferendis quae quam quibusdam recusandae sit ullam velit."/>

        <TextField
          label="Мотивувальна частина"
          fullWidth
          multiline
          variant="outlined"
          sx={{marginBottom: "20px"}}
          value="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam deserunt dolor doloribus ducimus error hic, illo libero necessitatibus nostrum nulla
          numquam quidem recusandae repellendus similique sit vel vero voluptatibus.Assumenda dignissimos dolore ipsa maiores natus. Aperiam aspernatur, assumenda at
          blanditiis cumque distinctio ea eaque eligendi enim explicabo fuga hic ipsa nihil perferendis quae quam quibusdam recusandae sit ullam velit."/>

        <TextField
          label="Прохальна частина"
          fullWidth
          multiline
          variant="outlined"
          sx={{marginBottom: "20px"}}
          value="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam deserunt dolor doloribus ducimus error hic, illo libero necessitatibus nostrum nulla
          numquam quidem recusandae repellendus similique sit vel vero voluptatibus.Assumenda dignissimos dolore ipsa maiores natus. Aperiam aspernatur, assumenda at
          blanditiis cumque distinctio ea eaque eligendi enim explicabo fuga hic ipsa nihil perferendis quae quam quibusdam recusandae sit ullam velit."/>

        <TextField
          label="Перелік додатків"
          fullWidth
          multiline
          variant="outlined"
          sx={{marginBottom: "20px"}}
          value="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam deserunt dolor doloribus ducimus error hic, illo libero necessitatibus nostrum nulla
          numquam quidem recusandae repellendus similique sit vel vero voluptatibus.Assumenda dignissimos dolore ipsa maiores natus. Aperiam aspernatur, assumenda at
          blanditiis cumque distinctio ea eaque eligendi enim explicabo fuga hic ipsa nihil perferendis quae quam quibusdam recusandae sit ullam velit."/>

      <div className="your-wishes">
        Ваші побажання та зауваження:
        <TextField
          id="outlined-multiline-static"
          label=""
          multiline
          rows={3}
          fullWidth
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <ButtonBlock
          value={value}
          deleteValue={deleteValue}
          addValue={addValue}
        />
      </div>
      <FooterButtonBlock
        step={step}
        setStep={setStep}
        isLoading={isLoading}
      />
    </div>
  )
};