import React from "react";

export const DocumentEdit = ({fillColor}) => {
  return (
    <svg id="Layer_1" height="32" fill={fillColor} style={{enableBackgroundNew: "0 0 52 52"}} version="1.1" viewBox="0 0 52 52" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
	<style type="text/css">
</style>
      <g><g>
		<path className="st0" d="M45.6519165,16.6408634c1.7962914,1.6340733,2.620697,1.7813034,2.302681,4.1490822
		c-0.2697029,2.0080528-1.5468826,2.7967052-2.9013443,4.1150837c-2.1364021,2.0794926-4.2728081,4.158987-6.409214,6.2384796
		c-2.2793236,2.218605-4.5785675,5.0002556-7.6327477,6.0461464c-2.1678295,0.7423668-4.1716213,0.7169533-6.2361755,1.823452
		c0.5508728,0.4234924,1.1017475,0.8469849,1.6526222,1.2704735c0.4703083-1.6190224,0.500782-3.3308067,0.9414883-4.9193649
		c1.0718803-3.8636627,3.5154285-6.0207939,6.1860714-8.7645531c1.6959724-1.7424049,9.8001404-12.18398,12.7151909-9.7041321
		c1.0969543,0.9331818,2.6959724-0.6509781,1.590992-1.5909901c-4.6940727-3.993269-9.171711,2.7945404-12.1579857,5.8625736
		c-2.9629478,3.0440674-6.7015381,6.0248928-9.1568871,9.491478c-1.8846092,2.6607819-1.4034271,5.9800224-2.2884979,9.0268517
		c-0.2828255,0.9736214,0.8090935,1.7225647,1.6526222,1.2704735c3.0849247-1.6533699,6.525219-1.2370949,9.3736572-3.4502716
		c3.6246567-2.816288,6.7882652-6.5598202,10.0780106-9.7619305c1.8073578-1.7592106,5.243309-3.9881153,5.7419586-6.6802044
		c0.4980431-2.6888332-2.1205788-4.4299774-3.861454-6.0136385C46.1737862,14.0773029,44.5780258,15.6639528,45.6519165,16.6408634
		L45.6519165,16.6408634z"/></g>
        <g><path className="st0" d="M39.5258217,20.2935238c1.2073097,0.3493347,2.2280426,1.8412228,3.080265,2.7090626
		c0.8879929,0.9042664,1.6555023,1.6119404,2.3679657,2.6683598c0.8053322,1.1941299,2.7554321,0.0693226,1.9428062-1.1356182
		c-0.4325752-0.6414127-0.7863922-1.4117184-1.3755951-1.9159565c-0.628994-0.5382881-1.2241936-1.0855827-1.8106155-1.6827526
		c-1.0775032-1.0972443-2.0770988-2.3701382-3.6066895-2.8127213    C38.7303848,17.7206688,38.1353302,19.8911896,39.5258217,20.2935238L39.5258217,20.2935238z"/>
	</g>
        <g>
		<path className="st0" d="M25.1720867,38.8353577c-5.4722595,0.051651-10.944519,0.1032982-16.4167786,0.1549492
		c-1.4470506,0.0136604-1.4506278,2.2636948,0,2.25c5.4722586-0.051651,10.9445181-0.1032982,16.4167786-0.1549492
		C26.6191368,41.0716972,26.6227131,38.8216629,25.1720867,38.8353577L25.1720867,38.8353577z"/>
	</g>
        <g>
		<path className="st0" d="M8.3389263,33.6037216c5.5555553,0,11.1111107,0,16.666666,0c1.4477673,0,1.4501667-2.25,0-2.25
		c-5.5555553,0-11.1111107,0-16.666666,0C6.8911581,31.3537216,6.8887606,33.6037216,8.3389263,33.6037216L8.3389263,33.6037216z"/>
	</g>
        <g>
		<path className="st0" d="M8.7555923,26.1870556c5.4722223,0,10.9444447,0,16.416666,0c1.4477692,0,1.4501667-2.25,0-2.25
		c-5.4722214,0-10.9444437,0-16.416666,0C7.3078251,23.9370556,7.3054271,26.1870556,8.7555923,26.1870556L8.7555923,26.1870556z"/>
	</g>
        <g>
		<path className="st0" d="M8.7555923,18.7703876c2.6111116,0,5.2222223,0,7.833334,0c1.4477673,0,1.4501648-2.25,0-2.25
		c-2.6111116,0-5.2222223,0-7.833334,0C7.3078251,16.5203876,7.3054271,18.7703876,8.7555923,18.7703876L8.7555923,18.7703876z"/>
	</g>
        <g>
		<path className="st0" d="M36.5968933,33.8063393c0.0450783,2.8336411,0.0901566,5.6672821,0.1352348,8.5009232
		c0.0200348,1.2593956,0.0400734,2.5187912,0.0601082,3.7781868c0.030201,1.8984566,0.4561768,2.260952-1.0701332,3.1058998
		c-1.9762955,1.0940552-6.5117416,0.2888794-8.8145618,0.2895546c-2.8872662,0.0008507-5.7745323-0.004879-8.6617737-0.0167122
		c-2.8872414-0.011837-5.7744579-0.0297813-8.6616201-0.0543022c-1.1992273-0.0101852-2.6778693,0.204998-3.849515-0.0364532
		c-1.8717687-0.3857269-2.0688982,0.2606392-2.3003945-2.0529099c-0.5717773-5.7142754,0.0116146-11.8066216,0.0172729-17.5585022
		c0.0054376-5.5276775,0.0108755-11.0553551,0.0163131-16.5830307c0.0022128-2.2493534-0.849448-6.7680664,0.0086365-8.7792521
		c1.0924525-2.5604992,4.8730993-1.7547991,7.1211729-1.7792678c2.7810125-0.0302696,5.5622253-0.0411053,8.3433876-0.0347731
		c3.8890781,0.0088544,5.8862991,0.3938086,9.0523052,3.1354816c1.7986832,1.5576081,3.5107384,3.2767515,5.1404362,5.008997    c3.0374603,3.2285881,3.4274635,5.0931368,3.4647675,9.3344088c0.0127296,1.4471073,2.2627602,1.4506054,2.25,0    c-0.0277863-3.1589241,0.319149-5.4934931-1.5546265-8.0270805c-2.1652374-2.92768-5.0546837-5.448359-7.7095871-7.9073153    c-1.4204292-1.3155944-2.8286591-2.9598856-4.6759834-3.6011236c-3.3212509-1.1528623-8.9729271-0.2038049-12.4566641-0.1758811    C9.0219517,0.380677,2.5878301-1.3532057,1.2283796,2.4487953C0.5988765,4.2093387,1.2235287,7.3799973,1.2216625,9.2771025    c-0.0022391,2.2761021-0.0044782,4.5522051-0.0067172,6.8283072c-0.0041583,4.227047-0.0083165,8.4540939-0.0124748,12.6811409    s-0.0083166,8.4540958-0.0124749,12.6811428c-0.0017838,1.8133926-0.9460919,7.4519081,0.2473887,9.0286484    c0.681613,0.9004974,1.3937652,0.8781013,2.3725193,1.1060486c3.9895153,0.9291534,9.3321095,0.0888557,13.4734497,0.1078529    c4.4912529,0.020607,8.9825821,0.0258598,13.4738712,0.01577c1.8592644-0.0041771,5.7777214,0.9039993,7.3197098-0.3840179    c1.3354874-1.1155243,0.9825401-2.72752,0.9803276-4.3119965c-0.0061607-4.4069443-0.1402702-8.8173561-0.2103691-13.2236595    C38.8238831,32.3599281,36.5738144,32.355545,36.5968933,33.8063393L36.5968933,33.8063393z"/></g>
        <g><path className="st0" d="M23.6096382,2.9787898c0.0168743,2.5877452,0.0337505,5.1754909,0.0506248,7.7632356    c0.0089474,1.37187-0.5748672,4.1699448,0.5680618,5.2289858c1.0502396,0.9731569,3.3529282,0.4631433,4.6175613,0.4638052    c2.6366158,0.0013809,5.2732315,0.0027618,7.9098473,0.0041428c1.4477997,0.0007591,1.4501343-2.249239,0-2.249999    c-1.8611412-0.0009747-3.7222824-0.0019503-5.5834217-0.0029249c-0.9305706-0.0004873-1.8611412-0.0009756-2.7917118-0.0014629    c-0.257473-0.0001345-2.6326237,0.1697912-2.7917118-0.001462c0.1510754,0.1626282,0.3283615-2.3697367,0.3277035-2.4706802    c-0.0063286-0.9704046-0.0126572-1.9408092-0.0189857-2.9112129c-0.0126553-1.9408092-0.0253124-3.8816185-0.0379677-5.8224273    C25.8501987,1.5314904,23.6001778,1.5282775,23.6096382,2.9787898L23.6096382,2.9787898z"/>
	</g></g>
</svg>
  )
};