import React from "react";
import "./App.scss";
import {Header} from "../header/Header";
import {StatusBar} from "../status-bar/StatusBar";
import {Step1} from "../step1/Step1";
import {Step2} from "../step2/Step2";
import {Step3} from "../step3/Step3";
import {Step4} from "../step4/Step4";
import {DrawerComponent} from "../Drawer";

export const App = () => {
  const [step, setStep] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  return (
    <div className="App">
      <Header open={open} setOpen={setOpen}/>
      <StatusBar step={step}/>
      <div className="container">
        {step === 1 && <Step1 step={step} setStep={setStep}/>}
        {step === 2 && <Step2 step={step} setStep={setStep}/>}
        {step === 3 && <Step3 step={step} setStep={setStep}/>}
        {step === 4 && <Step4 step={step} setStep={setStep}/>}
      </div>
      <DrawerComponent open={open} setOpen={setOpen}/>
    </div>
  )
};