import React, {useEffect, useState} from "react";
import "./step1.scss";
import {TextField} from "@mui/material";
import {ButtonBlock} from "../button-block/ButtonBlock";
import {useSelector, useDispatch} from "react-redux";
import {addMainText, deleteMainText} from "../../redux/TextSlice";
import {FooterButtonBlock} from "../footer-button-block/FooterButtonBlock";

interface Step1Props {
  step: number;
  setStep:  React.Dispatch<React.SetStateAction<number>>;
}

export const Step1: React.FC<Step1Props> = ({step, setStep}) => {
  // @ts-ignore
  const text = useSelector(state => state.text.step1Text);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(text);
  const [recomendations, setRecomendations] = React.useState("Вкажіть, будь ласка, точну інформацію щодо того, коли саме та яким " +
    "способом ви здійснили оплату, за можливості надайте копії підтверджуючих документів");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(`https://api.pretor.ai/api/get-token?user_id=user12345`)
      .then(response => response.text())
      .then(data => localStorage.setItem("token", data))
      .catch(error => console.log(error));
  }, []);

  const addValue = (value: string) => {
    setIsLoading(true);
    dispatch(addMainText(value));
    const token = localStorage.getItem("token")
    const options = {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        Authorization: token ? token : ""
      },
      body: `{"user_id": "user12345", "situation_desc": "${value}"}`
    };

    fetch("https://api.pretor.ai/api/situation-desc", options)
      .then(response => response.json())
      .then(response => setRecomendations(response.data))
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  };

  const deleteValue = () => {
    dispatch(deleteMainText());
    setValue("");
  };

  return (
    <div className="step1">
      <TextField
        label=""
        multiline
        rows={8}
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <ButtonBlock
        value={value}
        addValue={addValue}
        deleteValue={deleteValue}
      />
      <div className="our-recommendations">
        <p>Наші рекомендації:</p>
        {isLoading ?
          <div className="little-preloader">
            <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div> :
          <TextField
            label=""
            multiline
            rows={3}
            fullWidth
            disabled
            value={recomendations}
          />}
      </div>
      <FooterButtonBlock
        step={step}
        setStep={setStep}
        text={text}
        isLoading={isLoading}
      />
    </div>
  )
};