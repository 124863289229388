import React from "react";

export const Burger = ({fillColor}) => {
  return (
    <svg id="Layer_1_1_" height="32" fill={fillColor} style={{enableBackgroundNew: "0 0 16 16"}} version="1.1" viewBox="0 0 16 16" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect height="2" width="16"/>
      <rect height="2" width="16" y="7"/>
      <rect height="2" width="16" y="14"/>
    </svg>
  )
};