import React from "react";
import {Box, Button, Drawer, List, ListItem, Typography} from "@mui/material";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DrawerComponent: React.FC<IProps> = ({open, setOpen}) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Box sx={{width: "250px", padding: "25px 20px 10px 20px"}}>
        <Typography component="p" sx={{paddingBottom: "30px"}}>Pretor.ai</Typography>
        <Button variant="text" sx={{color: "black", textTransform: "none"}}><b>Нова заява</b></Button>
        <List>
          <ListItem sx={{display: "block"}}>
            <Typography component="p" className="date">
              20.04.2024
            </Typography>
            <Typography component="p" className="title">
              Позов щодо проблеми з арендою квартири в місті
            </Typography>
          </ListItem>
        </List>
        <List>
          <ListItem sx={{display: "block"}}>
            <Typography component="p" className="date">
              20.04.2024
            </Typography>
            <Typography component="p" className="title">
              Позов щодо проблеми з арендою квартири в місті
            </Typography>
          </ListItem>
        </List>
        <List>
          <ListItem sx={{display: "block"}}>
            <Typography component="p" className="date">
              20.04.2024
            </Typography>
            <Typography component="p" className="title">
              Позов щодо проблеми з арендою квартири в місті
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
};