import React from "react";
import {Button} from "@mui/material";

interface FooterButtonBlockProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  text?: string | undefined;
  isLoading: boolean;
}

export const FooterButtonBlock: React.FC<FooterButtonBlockProps> = ({
                                                                      step,
                                                                      setStep,
                                                                      text,
                                                                      isLoading
                                                                    }) => {
  return (
    <div className="footer-button-block" style={step === 1 ? {justifyContent: "flex-end"} : {justifyContent: "space-between"}}>
      {step > 1 && <Button
        sx={{padding: "10px 40px"}}
        variant="outlined"
        onClick={() => setStep(step - 1)}
      >
        {'<'} Попередній крок
      </Button>}
      {step < 4 && <Button
        sx={{padding: "10px 40px"}}
        variant="outlined"
        onClick={() => setStep(step + 1)}
        disabled={!text || isLoading}
      >
        Наступний крок {'>'}
      </Button>}
      {step === 4 && <Button
        sx={{padding: "10px 40px"}}
        variant="outlined">
        Завантажити текст заяви
      </Button>}
    </div>
  )
};