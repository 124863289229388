import React from "react";
import "./status-bar.scss";
import {Typography} from "@mui/material";
import {Book} from "../Book";
import {Burger} from "../Burger";
import {DocumentEdit} from "../DocumentEdit";
import {Scales} from "../Scales";

interface StatusBarProps {
  step: number;
}

export const StatusBar: React.FC<StatusBarProps> = ({step}) => {
  return (
    <div className="status-bar">
      <div className="status-item">
        <Burger fillColor={step === 1 ? "#414BB2" : "#000000"}/>
        <Typography component="p" sx={step === 1 ? {color: "#414BB2"} : {color: "#000000"}} variant="body1">1. Опис ситуації</Typography>
      </div>
      <div className="status-item">
        <Book fillColor={step === 2 ? "#414BB2" : step > 2 ? "#000000" : "#C5C5C5"}/>
        <Typography component="p" variant="body1" sx={step === 2 ? {color: "#414BB2"} : step > 2 ? {color: "#000000"} : {color: "#C5C5C5"}}>2. Пошук нормативних актів</Typography>
      </div>
      <div className="status-item">
          <DocumentEdit fillColor={step === 3 ? "#414BB2" : step > 3 ? "#000000" : "#C5C5C5"}/>
        <Typography component="p" variant="body1" sx={step === 3 ? {color: "#414BB2"} : step > 2 ? {color: "#000000"} : {color: "#C5C5C5"}}>3. Пошук прецедентів</Typography>
      </div>
      <div className="status-item">
          <Scales fillColor={step === 4 ? "#414BB2" : "#C5C5C5"}/>
        <Typography component="p" variant="body1" sx={step === 4 ? {color: "#414BB2"} : {color: "#C5C5C5"}}>4. Формування заяви</Typography>
      </div>
    </div>
  )
};