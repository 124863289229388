import React from "react";
import {Button} from "@mui/material";

interface ButtonComponentProps {
  title: string;
  color: string;
  borderColor: string;
  onClick: (event: React.MouseEvent) => void;
}

export const ButtonComponent: React.FC<ButtonComponentProps> = ({title, color, borderColor, onClick}) => {
  return (
    <Button
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(event)}
      sx={{color: color, textTransform: 'none', borderColor: borderColor, padding: "10px 40px"}}
      variant="outlined">
      {title}
    </Button>
  )
};