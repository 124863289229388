import React from "react";
import "./act-item.scss";

// @ts-ignore
import linkImage from "../../assets/img/svg/link_connection.svg"

interface ActItemProps {
  title: string;
  link: string;
}

export const ActItem: React.FC<ActItemProps> = ({title, link}) => {
  return (
    <div className="act-item">
      <a href={link}><img src={linkImage} alt="link icon"/></a>
      <p>
        {title}
      </p>
    </div>
  )
};